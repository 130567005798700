import Axios, { AxiosPromise } from 'axios';

interface UrlParams {
    [key: string]: any;
}

/**
 * Abstract API controller to call API commands from the backend
 */
export abstract class AbstractApi {
    /**
     * API handler page name. Needs to be defined in the concrete class.
     */
    protected static page: string;

    /**
     * Construct API command URL
     * @param  {string} module module name
     * @param  {string} cmd command name
     * @param  {UrlParams} urlParams request params
     * @return {string} URL
     */
    private static url(module: string, cmd: string, urlParams: UrlParams = {}): string {
        const params = new URLSearchParams();
        params.append('page', this.page);
        params.append('module', module);
        params.append('cmd', cmd);
        Object.keys(urlParams).forEach((key: string) => {
            params.append(key, urlParams[key]);
        });
        return '?' + params.toString();
    }

    /**
     * Make GET request to API and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static get(module: string, cmd: string, params: UrlParams = {}): AxiosPromise<any> {
        return Axios.get(this.url(module, cmd), {
            params,
        });
    }

    /**
     * Make GET pdf request to API and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static getPdf(module: string, cmd: string, params: UrlParams = {}): AxiosPromise<any> {
        return Axios.get(this.url(module, cmd), {
            params,
            headers: { 'Content-Type': 'application/pdf', Accept: 'application/pdf' },
            responseType: 'arraybuffer',
        });
    }

    /**
     * Make POST request to API with JSON data and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {any} json request json object
     * @return {AxiosPromise}
     */
    public static postJson(module: string, cmd: string, json: any = {}): AxiosPromise<any> {
        return Axios.post(this.url(module, cmd), json);
    }

    /**
     * Make POST request to API with params in URL and return results in a promise
     *
     * @param {string} module module name
     * @param {string} cmd command to execute
     * @param {UrlParams} urlParams request parameters
     * @return {AxiosPromise}
     */
    public static postUrl(module: string, cmd: string, urlParams: UrlParams = {}): AxiosPromise<any> {
        return Axios.post(this.url(module, cmd, urlParams));
    }
}

import Axios, { AxiosPromise } from 'axios';

interface UrlParams {
    [key: string]: any;
}

/**
 * Abstract API controller to call API commands from the backend
 */
export class DatatableApi {
    private static page = 'dt_admin';
    /**
     * Make GET request to API to fetch meta data of datatable and return results in a promise
     *
     * @param {string} view view name
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static getMeta(view: string, params: UrlParams = {}): AxiosPromise<any> {
        return Axios.get(`?page=${this.page}`, {
            params: {
                view,
                content: 'meta',
                ...params,
            },
        });
    }

    /**
     * Make GET request to API to fetch content of datatable and return results in a promise
     *
     * @param {string} view view name
     * @param {UrlParams} params additional parameters
     * @return {AxiosPromise}
     */
    public static getContent(view: string, params: UrlParams = {}): AxiosPromise<any> {
        return Axios.get(`?page=${this.page}`, {
            params: {
                view,
                ...params,
            },
        });
    }
}

export * from './NoSessionApi';
export * from './ManagementApi';
export * from './LegacyApi';
export * from './AjaxControllerApi';
export * from './DatatableApi';

export const handlePageReload = (event: BeforeUnloadEvent) => {
    event.returnValue = '';
};

/**
 * Axios response interceptor handler for errors
 *
 * @param {any} error
 * @returns {Promise<any>}
 */
export function axiosUnauthorizedErrorInterceptor(error: any): Promise<any> {
    if (error.response) {
        switch (error.response.status) {
            case 401: {
                window.removeEventListener('beforeunload', handlePageReload);

                const errorsMap: { [index: string]: string } = {
                    wrong_session: 'login.session.wrong.error',
                    expired_session: 'login.session_expired.error',
                    force_logout: 'login.session.force_logout.error',
                };
                let location = '?loggedOut=1';
                if (error.response.data && error.response.data.error && errorsMap[error.response.data.error]) {
                    location = `?error=${errorsMap[error.response.data.error]}`;
                }
                window.location.assign(location);
                break;
            }
        }
    }
    return Promise.reject(error);
}
